import { YuLifeLocale } from "hooks";

export const getStrapiLocale = (locale: YuLifeLocale) => {
  switch (locale) {
    case "us":
      return "en-US";
    case "za":
      return "en-ZA";
    case "jp":
      return "ja-JP";
    case "uk":
    default:
      return "en";
  }
};

/**
 * List of slugs that are in strapi for the legal pages
 */
const legalSlugObject = {
  uk: [
    "privacy-policy",
    "privacy-policy-website",
    "member-onboarding-privacy-policy",
    "cookie-policy",
    "yulife-app-eula",
    "terms-of-use",
  ],
  us: ["privacy-policy", "website-terms-and-conditions", "end-user-license-agreement-policy"],
  za: ["privacy-policy", "cookie-policy", "complaints"],
  jp: ["privacy-policy"],
};

export const legalSlugs = Object.keys(legalSlugObject).reduce((acc, region) => {
  return acc.concat(...legalSlugObject[region].map((slug) => ({ slug, region })));
}, []);
